// configPriority.js

// Use string type in selecPath
export const CONFIG_PRIORITY_CONFIGURE = '0';
export const CONFIG_PRIORITY_PRESENCE_CLIENT = '1';
export const CONFIG_PRIORITY_PRESENCE_USER = '2';

export const configPriority = {
  CONFIG_PRIORITY_CONFIGURE: 0,
  CONFIG_PRIORITY_PRESENCE_CLIENT: 1,
  CONFIG_PRIORITY_PRESENCE_USER: 2,
};
