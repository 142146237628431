// ErrorPage.jsx
'use client';
'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { TranslationNamespace } from '../resource/translationNamespace.js';
import {
  headerHeight,
  iPhoneXSeriesFooterPaddingInPWA,
  color,
  textColor,
} from '../style/variables.js';
import ResetHeadingStyle from '../style/ResetHeadingStyle.js';
import ResetButtonStyle from '../style/ResetButtonStyle.js';
import TextEllipsis from '../style/TextEllipsis.js';
import LogoImageSource from '../../img/login-swag-icon.svg';
import CryfaceImageSource from '../../img/img-empty-cryface.svg';

class ErrorPage extends React.PureComponent {
  render() {
    const { reportError, onErrorResolved, t } = this.props;
    return (
      <StyledErrorPage>
        <Header>
          <Logo src={LogoImageSource} alt="SWAG" />
        </Header>
        <Body>
          <InformationSection>
            <CryFaceIcon src={CryfaceImageSource} aria-hidden />
            <Title>Oops!</Title>
            <Description>
              {t('something_went_wrong', {
                ns: TranslationNamespace.NOTIFICATION,
              })}
            </Description>
            <Button onClick={reportError}>
              <ButtonText>
                {t('bug_report', {
                  ns: TranslationNamespace.SETTINGS,
                })}
              </ButtonText>
            </Button>
            <Button onClick={onErrorResolved}>
              <ButtonText>
                {t('back_to_home', {
                  ns: TranslationNamespace.SETTINGS,
                })}
              </ButtonText>
            </Button>
          </InformationSection>
        </Body>
      </StyledErrorPage>
    );
  }
}

ErrorPage.propTypes = {
  reportError: PropTypes.func,
  onErrorResolved: PropTypes.func,
  t: PropTypes.func.isRequired,
};

ErrorPage.defaultProps = {
  reportError: () => null,
  onErrorResolved: () => {
    location.href = '/';
  },
};

const StyledErrorPage = styled.div`
  position: relative;
  padding-top: ${headerHeight}px;
  padding-bottom: env(
    safe-area-inset-bottom,
    ${iPhoneXSeriesFooterPaddingInPWA}px
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: ${textColor.white};
`;

const Header = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
  border-bottom: 1px solid rgba(100, 100, 100, 0.25);
  width: 100%;
  height: ${headerHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color.grey['900']};
`;

const Logo = styled.img`
  width: 64px;
  height: auto;
`;

const Body = styled.div`
  width: 100%;
  flex: auto;
`;

const InformationSection = styled.div`
  padding: 32px 78px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CryFaceIcon = styled.img`
  margin-bottom: 20px;
  width: 60px;
  height: 60px;
`;

const Title = styled.h3`
  ${ResetHeadingStyle};
  margin-bottom: 12px;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  line-height: 1;
`;

const Description = styled.h4`
  ${ResetHeadingStyle};
  margin-bottom: 32px;
  font-size: 12px;
  text-align: center;
  line-height: 1;
`;

const Button = styled.button`
  ${ResetButtonStyle};
  margin-bottom: 20px;
  border: 1px solid rgba(0, 210, 190, 0.9);
  border-radius: 22px;
  padding-left: 20px;
  padding-right: 20px;
  height: 36px;
  width: 100%;
  max-width: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  :last-child {
    margin-bottom: 0px;
  }
`;

const ButtonText = styled.div`
  ${TextEllipsis};
  color: ${textColor.primary};
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
`;

export default withTranslation()(ErrorPage);
