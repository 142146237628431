// ExternalModule.js
import { MERGE_EXTERNAL_DATA } from '../ActionTypes.js';

export const EXTERNAL_EVENT_TYPE = 'externals';

/**
 * ExternalModule is a singleton class that subscribes to external events and dispatches them.
 */
class ExternalModule {
  static #instance = null;

  /**
   * Creates an instance of ExternalModule.
   * @param {Object} options - The options for the module.
   * @param {Function} options.dispatch - The dispatch function to handle events.
   * @returns {ExternalModule} The singleton instance of ExternalModule.
   */
  constructor({ dispatch }) {
    if (ExternalModule.#instance) {
      return ExternalModule.#instance;
    }
    ExternalModule.#instance = this;
    this.dispatch = dispatch;
  }

  /**
   * Subscribes to an external event and dispatches it when triggered.
   * @param {Object} options - The options for the subscription.
   * @param {EventTarget} options.externalEventTarget - The event target to listen to.
   * @param {string} options.name - The name to associate with the event data.
   * @throws {Error} If externalEventTarget is not an instance of EventTarget.
   */
  subscribe({ externalEventTarget, name }) {
    if (!externalEventTarget || !(externalEventTarget instanceof EventTarget)) {
      throw Error('externalEventTarget should be the instance of EventTarget');
    }

    externalEventTarget.addEventListener(
      EXTERNAL_EVENT_TYPE,
      ({ detail: data }) => {
        this.dispatch({
          type: MERGE_EXTERNAL_DATA,
          payload: {
            selectPath: [name],
            data,
          },
        });
      }
    );
  }
}

export default ExternalModule;
