// Delay.js
'use strict';

class Delay {
  constructor(msec = 500) {
    this.msec = msec || 500;
    this.timeout = null;
  }
  setMsec(msec) {
    this.msec = msec;
    return this;
  }
  promise(msec) {
    if (this.timeout) {
      this.cancel();
    }
    return new Promise(resolve => {
      this.resolvePromise = resolve;
      this.timeout = setTimeout(() => {
        this.timeout = null;
        resolve(this);
      }, msec || this.msec);
    });
  }
  cancel() {
    clearTimeout(this.timeout);
    this.timeout = null;
    this.resolvePromise();
  }
}

export default Delay;
