// defaults.js
'use strict';

export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};
export const NULL_FUNCTION = () => null;

export default {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  NULL_FUNCTION,
};
