// createTrackingEvent.js
'use client';
import { getIsInBrowserMainThread } from './getJsEnvironment.js';
import trackMixpanel, { EventTypes, getViewId } from './mixpanel.js';

const isBrowserMainThread = getIsInBrowserMainThread();

const INTERACTIVE_TRACKING_ELEMENTS = [
  'A',
  'BUTTON',
  'INPUT',
  'SELECT',
  'TEXTAREA',
  'OPTGROUP',
  'OPTION',
  'DATALIST',
  'DETAILS',
  'AUDIO',
  'VIDEO',
];

// Override stopPropagation
Event.prototype.originalStopPropagation = Event.prototype.stopPropagation;
Event.prototype.stopPropagation = function () {
  const event = this;
  if (event.type === 'click') {
    dispatchGlobalClickIfNeeded(event);
  }
  event.originalStopPropagation();
};

/**
 * handle `globalclick` event.
 * @param {Event} event
 */
function handleGlobalClick(event) {
  if (!event?.detail) {
    return null;
  }
  const {
    metadata,
    viewId,
    buttonId,
    messageId,
    modalId,
    abData,

    // The following fields are declared explicitly
    // since we don't want them left in `restProps`.
    identifier,
    identifierSource,
    detailedPath,
    srcTarget,
    originalEvent,

    ...restProps
  } = event.detail;

  const userInputs = Object.create(null);
  if (originalEvent?.target?.type === 'submit' && originalEvent?.target?.form) {
    const inputElements =
      originalEvent?.target?.form.querySelectorAll('input[data-element_id]') ||
      [];
    inputElements.forEach(inputElement => {
      // (HEADS-UP) It's `form_tracking_disabled`, not `click_tracking_disabled`
      if (!inputElement.dataset.form_tracking_disabled) {
        userInputs[inputElement.dataset.element_id] = inputElement.value;
      }
    });
  }

  trackMixpanel({
    type: EventTypes.USER_CLICKED,
    payload: {
      metadata,
      viewId,
      buttonId: buttonId || identifier,
      messageId,
      modalId,
      abData,
      extraProps: {
        ...restProps,
        ...userInputs,
        'button.id.source': buttonId ? 'element-id' : identifierSource,
        'path.detail': detailedPath,
      },
    },
  });
}

/**
 * Dispatch a custom `globalclick` event.
 * @param {Event} event
 * @param {String} browsingContext
 * @param {Object} trackingPayloadSuperProps
 */
export function dispatchGlobalClickIfNeeded(
  event,
  browsingContext,
  trackingPayloadSuperProps
) {
  const { target } = event;
  if (
    !target.isTrackedByMonkeyPatch &&
    INTERACTIVE_TRACKING_ELEMENTS.includes(target.tagName)
  ) {
    if (target?.dataset?.['click_tracking_disabled']) return;

    let payload = {
      ...trackingPayloadSuperProps, // To allow iframe to provide tracking payload from the outside.
    };
    if (target?.dataset?.['element_id']) {
      payload = {
        ...payload,
        buttonId: target.dataset['element_id'],
      };
    }
    if (target?.dataset?.['tracking_payload']) {
      try {
        const trackingPayload = JSON.parse(
          target?.dataset?.['tracking_payload']
        );
        payload = {
          ...payload,
          ...trackingPayload,
        };
      } catch (e) {
        // Not a valid JSON string
        window.__ERROR_RECOVERY_WORDING__ = `Invalid payload: ${e}`;
        window.__showErrorRecoveryToast__();
      }
    }

    window.dispatchEvent(
      new CustomEvent('globalclick', {
        detail: {
          originalEvent: event,
          ...payload,
          ...createTrackingEvent(event.target, browsingContext),
        },
      })
    );
  }
  target.isTrackedByMonkeyPatch = undefined;
}

/**
 * Get the path between an element and the root element.
 * @param {Element} element
 * @param {String} browsingContext
 * @returns {String[]} the path from a specific element all the way up to the root element
 */
export function getElementPaths(element, browsingContext) {
  const detailedPath = [];
  while (element.parentNode) {
    if (element.id) {
      detailedPath.push('#' + element.id);
      break;
    } else {
      const classes = getConsistentClass(element.classList);
      if (
        element === element?.ownerDocument?.documentElement ||
        element === element?.ownerDocument?.body ||
        (!element.previousElementSibling && !element.nextElementSibling)
      ) {
        detailedPath.push(element.tagName + classes);
      } else {
        let nth = 1;
        let el = element;
        while (el.previousElementSibling) {
          el = el.previousElementSibling;
          ++nth;
        }
        detailedPath.push(element.tagName + classes + `:nth-child(${nth})`);
      }
      element = element.parentNode;
    }
  }
  if (browsingContext) {
    detailedPath.push(browsingContext);
  }
  return {
    detailedPath: detailedPath.reverse().join(' > '),
  };
}

/**
 * Clear the hash from Styled Components's classes,
 * and keep the processed identifierString as consistent as possible
 * @param {DOMTokenList} classList
 * @returns {String} an identifier string.
 */
export function getConsistentClassFromComponent(classList) {
  let identifierString = '';
  classList.forEach(cssClass => {
    if (cssClass.includes('-sc-')) {
      identifierString += '.' + cssClass.split('-sc-')[0];
    }
  });
  return identifierString;
}

/**
 * Get classes either from Styled components or global classes
 * @param {DOMTokenList} classList
 * @returns {String} classes
 */
export function getConsistentClass(classList) {
  let classes = '';
  if (classList?.length) {
    if (classList.value.includes('-sc-')) {
      classes += getConsistentClassFromComponent(classList);
    } else {
      classes += '.' + classList.value.split(' ').join('.');
    }
  }
  return classes;
}

/**
 * Create a custom tracking event.
 * @param {Element} target - currentTarget for React element; target for native element
 * @param {String} browsingContext
 * @returns {Object} a custom tracking event
 * An element identifier would be chosen in order of priority:
 *   1) Target element's ID
 *   2) Target element's button_id
 *   3) Target element's href attribute if it's element of <a>
 *   4) Target element's CSS classes
 *      4.1) Global CSS classes
 *      4.2) CSS classes generated by Styled components.
 *   5) Target element's tag name
 */
export function createTrackingEvent(target, browsingContext) {
  const { id, tagName, classList } = target;
  const trackingEvent = Object.create(null);
  let elementIdentifierSource = 'tag-name';
  let elementIdentifier = tagName;
  if (id) {
    elementIdentifierSource = 'id';
    elementIdentifier += `#${id}`;
  } else if (tagName === 'A') {
    elementIdentifierSource = 'anchor-classlist';
    trackingEvent.href = target.getAttribute('href');

    // The links (specifically, the `href` attribute) might be dynamic.
    // So, we perfer Components (with Styled components' classes) to hrefs.
    // (But we still keep `href` as part of the tracking data)
    if (classList?.length && classList.value.includes('-sc-')) {
      elementIdentifier += getConsistentClassFromComponent(classList);
    } else if (
      target?.firstChild?.classList?.length &&
      target.firstChild.classList.value.includes('-sc-')
    ) {
      elementIdentifier +=
        ' > ' +
        target.firstChild.tagName +
        getConsistentClassFromComponent(target.firstChild.classList);
    } else {
      elementIdentifierSource = 'anchor-href';
      elementIdentifier += `[href="${target.getAttribute('href')}"]`;
    }
  } else if (classList?.length) {
    elementIdentifierSource = 'classlist';
    elementIdentifier += getConsistentClass(classList);
  }

  return Object.assign(
    trackingEvent,
    getElementPaths(target, browsingContext),
    {
      srcTarget: 'element', // 'component' (from React) | 'element' (fallback)
      identifier: elementIdentifier,
      identifierSource: elementIdentifierSource,
      viewId: getViewId({ pathname: window.location.pathname }),
    }
  );
}

if (isBrowserMainThread) {
  // A custom event handler for tracking all the click events.
  window.addEventListener('globalclick', handleGlobalClick);

  // A global click event listener.
  // Currently, it's a fallback for the monkey patch of React.createElement.
  window.addEventListener('click', dispatchGlobalClickIfNeeded);
}

export default createTrackingEvent;
