// WorkerMessenger.js
'use strict';
import { v4 as uuidv4 } from 'uuid';

import { workerMessenger as workerMessengerDebug } from '../resource/debug.js';
import safeStructuredClone from '../resource/safeStructuredClone.js';

const workerMessengerLog = workerMessengerDebug.extend('log');
const workerMessengerErrorLog = workerMessengerDebug.extend('error');

/**
 * Callback for getting message target. This is to support multiple targets like service worker
 * @async
 * @callback getMessageTargetCallback
 * @param {string} tabId - Value come from `options.tabId`, a string representing the id of the client you want to get. See https://developer.mozilla.org/en-US/docs/Web/API/Clients/get
 * @return {Promise<Client>} The target client for `.postMessage()`
 */

/**
 * WorkerMessenger is an interface to communicate with worker, service worker.
 * It implement both sender and receiver side.
 * Ref: https://github.com/swaglive/swag-webapp/discussions/7804
 */
class WorkerMessenger {
  /**
   * Create a workerMessenger.
   * @param {string} {name} - Name for worker messenger, mostly for logging.
   * @param {object} {[scope={}]} - Scope for peer interfaces to run.
   * @param {getMessageTargetCallback} {getMessageTarget} - Callback function to obtain postMessage() target, here we call it peer.
   * @param {EventTarget} {listenTarget} - Target to listen interface calling result and peer interface events.
   * @param {string} {messageIdKey} - Message id property name on message object, to identify message from other purpose events.
   */
  constructor({
    name = 'serviceWorker',
    scope = {},
    getMessageTarget,
    listenTarget,
    messageIdKey = 'workerMessengerId',
  }) {
    this.log = workerMessengerLog.extend(name);
    this.errorLog = workerMessengerErrorLog.extend(name);

    const targets = {
      getMessageTarget,
      listenTarget,
    };
    Object.keys(targets).forEach(key => {
      if (!targets[key]) {
        const errorMessage = `Need ${key}, got ${targets[key]}`;
        this.errorLog('contructor()', errorMessage);
        throw new Error(errorMessage);
      }
    });

    this.name = name;

    // For invoke mainThread.get|set|apply() inside of worker
    this.scope = scope;
    this.scope.workerMessenger = this;

    this.getMessageTarget = getMessageTarget;
    this.messageIdKey = messageIdKey;

    // { [workerMessengerId]: { resolve, reject, message } }
    this._feedbackHandlers = {};

    this.listenTarget = listenTarget;
    this._thisEventHandler = this._eventHandler.bind(this);
    this.listenTarget.addEventListener('message', this._thisEventHandler);

    this.log('contructor finish', { messenger: this });
  }

  /**
   * Get value/object from the message target scope
   * @param {array} {selectPath} - node path from scope toward the get target
   * @param {Object} {[options={}]} - options
   */
  get({ selectPath, options = {} }) {
    const interfaceName = 'get';
    const messageComposer = ({ selectPath, options }, workerMessengerId) => {
      const message = {
        interface: interfaceName,
        selectPath,
        options,
        [this.messageIdKey]: workerMessengerId,
      };
      return message;
    };
    const messageProcessor = this._createMessageProcessor({
      messageComposer,
      interfaceName,
    });
    return messageProcessor({ selectPath, options });
  }

  /**
   * Set value/object to the message target scope
   * @param {array} {selectPath} - node path from scope toward the set target, missing nodes will be create
   * @param {any} {value} - value to be set, will be transform by safeStructuredClone()
   * @param {Object} {[options={}]} - options
   */
  set({ selectPath, value, options = {} }) {
    const interfaceName = 'set';
    const messageComposer = (
      { selectPath, value, options },
      workerMessengerId
    ) => {
      const message = {
        interface: interfaceName,
        selectPath,
        value: safeStructuredClone({
          value,
          options: { objectDepth: options.objectDepth },
        }),
        options,
        [this.messageIdKey]: workerMessengerId,
      };
      return message;
    };
    const messageProcessor = this._createMessageProcessor({
      messageComposer,
      interfaceName,
    });
    return messageProcessor({ selectPath, value, options });
  }

  /**
   * Call function in the message target scope, with Function.prototype.apply() pattern
   * @param {array} {selectPath} - node path from scope toward the target function
   * @param {array} {thisSelectPath} - node path from scope toward the 'this' target for apply
   * @param {array} {args} - apply(_, args), each value will be transformed by safeStructuredClone()
   * @param {Object} {[options={}]} - options
   */
  apply({ selectPath, thisSelectPath, args, options = {} }) {
    const interfaceName = 'apply';
    const messageComposer = (
      { selectPath, thisSelectPath, args, options },
      workerMessengerId
    ) => {
      const message = {
        interface: interfaceName,
        selectPath,
        thisSelectPath,
        args: args.map(v =>
          safeStructuredClone({
            value: v,
            options: {
              objectDepth: options.objectDepth,
            },
          })
        ),
        options,
        [this.messageIdKey]: workerMessengerId,
      };
      return message;
    };
    const messageProcessor = this._createMessageProcessor({
      messageComposer,
      interfaceName,
    });
    return messageProcessor({ selectPath, thisSelectPath, args, options });
  }

  /**
   * Destroy the worker messenger
   */
  destroy() {
    this.log('start destroy()', {
      name: this.name,
      messageIdKey: this.messageIdKey,
    });

    this.listenTarget.removeEventListener('message', this._thisEventHandler);
    delete this._thisEventHandler;

    Object.keys(this._feedbackHandlers).forEach(workerMessengerId => {
      if (this._feedbackHandlers[workerMessengerId]) {
        this._feedbackHandlers[workerMessengerId].reject?.(
          new Error(`WorkerMessenger ${this.name} is destroyed.`)
        );
        delete this._feedbackHandlers[workerMessengerId].reject;
        delete this._feedbackHandlers[workerMessengerId].resolve;
        delete this._feedbackHandlers[workerMessengerId].message;
      }
      delete this._feedbackHandlers[workerMessengerId];
    });

    delete this.scope.workerMessenger;
    delete this.scope;

    delete this.getMessageTarget;
    delete this.listenTarget;
  }

  _createMessageProcessor({ messageComposer, interfaceName }) {
    return interfaceInput => {
      const workerMessengerId = uuidv4();
      const promise = new Promise((resolve, reject) => {
        const handleError = error => {
          this.errorLog(`${interfaceName}() error`, {
            ...interfaceInput,
            error,
            messenger: this,
          });
          delete this._feedbackHandlers[workerMessengerId];
          reject(error);
        };
        try {
          const message = messageComposer(interfaceInput, workerMessengerId);

          this._feedbackHandlers[workerMessengerId] = {
            resolve,
            reject,
            message,
          };

          this.log(`${interfaceName}()`, {
            workerMessengerId,
            message,
            messenger: this,
          });
          this.getMessageTarget(interfaceInput.options.tabId)
            .then(client => {
              client.postMessage(message);
            })
            .catch(error => {
              // this error can't be catched by try/catch below.
              handleError(error);
            });
        } catch (error) {
          handleError(error);
        }
      });

      if (interfaceInput.options.timeoutMsec) {
        return this._racer({
          timeoutMsec: interfaceInput.options.timeoutMsec,
          workerMessengerId,
          promise,
          errorMessage: `${interfaceName}() timeout, input: ${JSON.stringify(interfaceInput)}`,
        });
      } else {
        return promise;
      }
    };
  }

  _eventHandler(event) {
    const data = event?.data || event?.detail; // read .detail to also process CustomEvent on fallback
    const workerMessengerId = data?.[this.messageIdKey];
    if (!workerMessengerId) {
      // not our events
      return;
    }
    this.log('got()', { workerMessengerId, event, messenger: this });

    if (data.interface) {
      // handle get|set|apply
      this._interfaceEventHandler(event);
      return;
    }

    // handle feedback message
    const handler = this._feedbackHandlers[workerMessengerId];
    // to make sure handler only handle once, ex: exclude multiple error in worker
    delete this._feedbackHandlers[workerMessengerId];
    if (!handler) {
      this.log('repeated got()', { workerMessengerId, event, messenger: this });
      return;
    }

    if (data.error) {
      this.errorLog('got() error', data.error);
      handler.reject(data.error);
      return;
    }
    handler.resolve(data.message);
    return;
  }

  async _interfaceEventHandler(event) {
    const data = event?.data || event?.detail; // read .detail to also process CustomEvent on fallback
    this.log('process message', data);

    const messageIdKey = this.messageIdKey;
    const tabId = event.source?.id; // CustomEvent on fallback won't contains .source
    const handleError = error => {
      const payload = {
        [messageIdKey]: data[messageIdKey],
        error,
        data,
      };
      this.log('process message error', data, payload);
      this.getMessageTarget(tabId).then(client => {
        client.postMessage(payload);
      });
    };

    let payload = {
      [messageIdKey]: data[messageIdKey],
    };
    let processError = null;
    try {
      switch (data.interface) {
        case 'get': {
          const selectPath = data.selectPath || [];
          let pointer = this.scope;
          selectPath.forEach?.(node => {
            if (pointer[node]) {
              pointer = pointer[node];
            } else {
              throw new Error(
                `node: ${node} not found in ${JSON.stringify(selectPath)}`
              );
            }
          });
          this.log('process message get()', data, pointer);
          payload = {
            [messageIdKey]: data[messageIdKey],
            message: safeStructuredClone({
              value: pointer,
              options: { objectDepth: data.options.objectDepth },
            }),
            options: data.options,
          };
          break;
        }
        case 'set': {
          const selectPath = data.selectPath;
          if (!Array.isArray(selectPath) || !selectPath.length) {
            throw new Error(`Invalid selectPath: ${selectPath}`);
          }
          let pointer = this.scope;
          const nodes = [...selectPath];
          const tail = nodes.pop();
          nodes.forEach?.(node => {
            if (!pointer[node]) {
              pointer[node] = {};
            }
            pointer = pointer[node];
            if (!pointer) {
              throw new Error(
                `Invalid node: ${node} in ${JSON.stringify(selectPath)}`
              );
            }
          });
          this.log('process message set()', data, pointer);
          payload = {
            [messageIdKey]: data[messageIdKey],
            message: safeStructuredClone({
              value: Object.assign(pointer, { [tail]: data.value }),
              options: { objectDepth: data.options.objectDepth },
            }),
            options: data.options,
          };
          break;
        }
        case 'apply': {
          const selectPath = data.selectPath;
          if (!Array.isArray(selectPath)) {
            throw new Error(`Invalid selectPath: ${selectPath}`);
          }
          let pointer = this.scope;
          selectPath.forEach?.(node => {
            if (pointer[node]) {
              pointer = pointer[node];
            } else {
              throw new Error(
                `node: ${node} not found in ${JSON.stringify(selectPath)}`
              );
            }
          });

          const thisSelectPath = data.thisSelectPath;
          let thisPointer = undefined;
          if (thisSelectPath) {
            thisPointer = this.scope;
            thisSelectPath.forEach?.(node => {
              if (thisPointer[node]) {
                thisPointer = thisPointer[node];
              } else {
                throw new Error(
                  `node: ${node} not found in ${JSON.stringify(thisSelectPath)}`
                );
              }
            });
          }

          this.log('process message apply()', data, pointer, thisPointer);
          const applyResult = await pointer.apply(thisPointer, data.args);

          payload = {
            [messageIdKey]: data[messageIdKey],
            message: safeStructuredClone({
              value: applyResult,
              options: { objectDepth: data.options.objectDepth },
            }),
            options: data.options,
          };
          break;
        }
        default:
          break;
      }
      return;
    } catch (error) {
      processError = error;
    } finally {
      if (!processError) {
        try {
          const client = await this.getMessageTarget(tabId);
          this.log('done process message', { data, payload, client });
          client.postMessage(payload);
        } catch (postMessageError) {
          this.errorLog('handler() feedback error', {
            error: postMessageError,
            payload,
            data,
          });
          handleError(postMessageError);
        }
      } else {
        handleError(processError);
      }
    }
  }

  _racer({ timeoutMsec, workerMessengerId, errorMessage, promise }) {
    return Promise.race([
      new Promise(resolve => {
        setTimeout(() => {
          this.errorLog(errorMessage);
          const { reject } = this._feedbackHandlers[workerMessengerId] || {};
          delete this._feedbackHandlers[workerMessengerId];
          reject?.(new Error(errorMessage));
          resolve();
        }, timeoutMsec);
      }),
      promise,
    ]);
  }
}

export default WorkerMessenger;
