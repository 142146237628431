// elasticSearchApm.js
'use strict';
import env from '../resource/env.js';
import { getMixpanelViewId } from '../resource/getMixpanelViewId.js';

const isClient = typeof window !== 'undefined';

let isRUMEnabled = false;
let RUM_SAMPLE_RATE = false;
let RUM_SERVER_URL = false;
let apmInstance = undefined;
let isEnabled = false;

if (isClient) {
  isRUMEnabled = env.RUM === true || env.RUM === 'true';
  RUM_SAMPLE_RATE = env.RUM_SAMPLE_RATE;
  RUM_SERVER_URL = env.RUM_SERVER_URL;
}

export const initialize = async () => {
  if (!isClient) return false;
  if (isRUMEnabled && RUM_SERVER_URL && RUM_SAMPLE_RATE) {
    const shouldLoadScript = Math.random() < Number(RUM_SAMPLE_RATE);
    if (shouldLoadScript) {
      const serviceName = window.location.hostname.replace(/\./g, '-');
      apmInstance = (await import('@elastic/apm-rum')).init({
        serviceName,
        serverUrl: RUM_SERVER_URL,
        active: true,
      });
      isEnabled = true;
      const transaction = apmInstance.startTransaction(
        getMixpanelViewId({ pathname: location.pathname }),
        'connection'
      );
      transaction.addLabels({
        'swag.connection.downlink': navigator.connection?.downlink,
      });
      transaction.addLabels({
        'swag.connection.effectiveType': navigator.connection?.effectiveType,
      });
      transaction.addLabels({
        'swag.connection.rtt': navigator.connection?.rtt,
      });
      transaction.end();
    }
  }
};

export const getApmInstance = () => {
  if (!isEnabled) return false;
  return apmInstance;
};
